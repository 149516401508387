/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';

const Why = () => {

	return (
		<div class="whyMain">
		<div data-aos="fade-up" data-aos-duration="1000">
			<div class="title">WHY EL SALVADOR?</div>

			<p>FIRST COUNTRY TO ADOPT BITCOIN AS A LEGAL TENDER</p>
			<p>GROWING ECONOMY WITH A FOCUS ON TECHNOLOGICAL INNOVATION</p>
			<p>BEAUTIFUL LANDSCAPES AND BOOMING TOURISM INDUSTRY</p>
			<p>TRANSFORMATION OF LEGAL FRAMEWORK FOR NFTS, DAOS, AND TOKENIZATION</p>
			<p>SECURE AND TRANSPARENT TRANSACTIONS</p>
			<p>DAO GOVERNANCE MODEL FOR PROJECT DECISIONS</p>
			<p>TOKENIZATION OF PROPERTY ASSETS</p>
			<p>NETWORKING THRU PRIVATE EXCLUSIVE EVENTS AND MEET & GREETS</p>
		</div >
		</div >
	)

}
export default Why;
