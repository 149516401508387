/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import card1 from '../assets/brand2.png';
import card2 from '../assets/brand1.png';
import card3 from '../assets/brand3.png';

const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const DAO = () => {


	return (
		<div class="daoMainBg">
			<div class="daoMain" data-aos="fade" data-aos-duration="1000">
				<div class="title">Sv Luxury DAO Properties</div>
				<p>Unlocking fractionalized real. estate through blockchain technology with SV Luxury DAO Membership offers a revolutionary approach to property investment. This innovative system democratizes access to luxury real estate markets, enabling a broader range of investors to own shares in high-value properties. </p>
				<p>By leveraging the transparency, security, and efficiency of blockchain, SV Luxury DAD Membership facilitates fractional ownership, allowing members to invest in real estate with lower capital requirements.</p>
				<p>This method not only simplifies the investment process but also provides liquidity and flexibility, as shares can be easily traded on digital platforms. Moreover, the decentralized nature of DAOs ensures that all members have a voice in decision-making processes, fostering a community-driven approach to investment.</p>
				</div>

				<div className='mainCards'>
					<div className='mainCardsSub'>
						<img src={card1} />
						<div><span className='bold'>Bitcoin mansion</span> <br /> (property value $3.5M) <br /> $22k-$39K/Month <br /> Management Net</div>
					</div>

					<div className='mainCardsSub'>
						<img src={card2} />
						<div><span className='bold'>Ensenada</span> <br /> ($20M developed) <br /> $50K-$64K/Month <br /> Management Net</div>
					</div>

					<div className='mainCardsSub'>
						<img src={card3} />
						<div><span className='bold'>Playa La Ventana</span> <br /> ($22M Developed) <br /> $70K-$88K/Month <br /> management Net</div>
					</div>
			</div>
		</div >
	)

}
export default DAO;
