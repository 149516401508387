/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';



const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const About = () => {


	return (
		<div class="aboutMain" data-aos="fade" data-aos-duration="1000">
			<div class="title">About</div>
			<p>At SV.Luxury Real Estate Academy, we don't just teach real estate; we mentor you to become a visionary in the field. With our blend of practical knowledge, market insights, and expert guidance, you're not just learning - you're preparing to lead.</p>
			<p><span className='bold'>Ready to Elevate Your Real Estate Journey?</span> <br/>Join the elite circle of successful real estate professionals by enrolling in SV.Luxury Real Estate Academy today. Discover the tools, techniques, and secrets to excel in the Salvadoran luxury real estate market. Your path to real estate mastery starts here. </p>
		</div >
	)

}
export default About;
